/** @format */

import "./App.scss";
import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "notiflix/dist/notiflix-3.2.6.min.css";
import "notiflix/dist/notiflix-3.2.6.min.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Base from "./components/layout/Base";
import Home from "./pages/Home";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import ReactGA from "react-ga";
ReactGA.initialize("UA-258508156-1");
ReactGA.pageview(window.location.pathname + window.location.search);
const App = () => {
  function getLibrary(provider) {
    return new Web3(provider);
  }
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <Base>
          <Routes>
            <Route index path='/' element={<Home />} />
          </Routes>
        </Base>
      </BrowserRouter>
    </Web3ReactProvider>
  );
};

export default App;
