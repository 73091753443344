/** @format */

import { useState } from "react";
import { FaSearch, FaTelegramPlane } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { useWeb3React } from "@web3-react/core";
import Login from "./Login";
import Cards from "./Cards";

function SearchBar() {
  const { active } = useWeb3React();
  const [err, setErr] = useState("");

  const [image, setImage] = useState(null);
  const [search, setSearch] = useState(null);
  const [prg, setPrg] = useState(5);
  const [percent, setPercent] = useState(25);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setImage(null);
  };
  const handleShow = () => setShow(true);

  const openai_api_key = "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";
  console.log(search);

  const submit = async e => {
    setErr("");
    console.log(search);
    if (!search) {
      setErr("** Please Enter your something");
      return;
    }
    handleShow();

    const params = {
      prompt: search,
      n: 1,
      size: "512x512",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(openai_api_key),
      },
      body: JSON.stringify(params),
    };

    try {
      const response = await fetch(
        "https://api.openai.com/v1/images/generations",
        requestOptions,
      );
      const data = await response.json();
      console.log(data);
      // console.log(response);
      const imageUrl = data.data[0].url;
      console.log(imageUrl);
      setImage(imageUrl);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {active ? (
        <>
          <div className='banner'></div>

          <div className='logo text-center'>
            <img alt='Google' src='assets/footer.svg' width='10%' />
            <p className='mt-3'>Create, Customize & Sell your own NFTs</p>
            <a
              href='https://t.me/AI2_CLUB'
              target='_blank'
              rel='noopener noreferrer'>
              <FaTelegramPlane color='#858C94' /> &nbsp; Join The Telegram
            </a>
          </div>
          <div className='bar'>
            <span>
              <FaSearch className='voice' />
            </span>
            <input
              className='searcher'
              type='text'
              placeholder='Search'
              title='Search'
              value={search}
              onChange={e => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <p className='text-danger text-center mt-2'>{err}</p>

          <div className='buttons text-center mt-4'>
            <button className='button' type='submit' onClick={submit}>
              <span>Generate</span>
            </button>
          </div>
          <Cards />

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Your Images</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {image ? (
                <div className='image'>
                  <img src={image} alt='image' className='w-100' />
                </div>
              ) : (
                <div className='loading-sec'>
                  <div className='loading'>Loading&#8230;</div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className='buttons  m-auto'>
                <button className='button' type='submit' onClick={handleShow}>
                  <span> Sell Art </span>
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <>
          <div className='banner'></div>

          <Login />
        </>
      )}
      {/* {image && (
        <div className='image'>
          <img src={image} alt='image' className='w-100' />
        </div>
      )} */}
    </>
  );
}

export default SearchBar;
