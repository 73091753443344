/** @format */

import React from "react";
import { Button } from "react-bootstrap";

import { useWeb3React } from "@web3-react/core";
import { injected } from "./connectors";
import Notiflix from "notiflix";
import { useEffect } from "react";

export const ConnectWallet = ({ classN }) => {
  const { account, error, activate } = useWeb3React();

  async function connect() {
    Notiflix.Block.pulse(".box");

    try {
      await activate(injected)
      // localStorage.setItem("@user", account);
      // window.location.reload();


      Notiflix.Block.remove(".box", 1000);
    } catch (err) {
      console.log(err);
      Notiflix.Block.remove(".box", 1000);
      Notiflix.Notify.failure(err.message);
    }
  }
  useEffect(() => {
    if (error) {
      Notiflix.Notify.failure(error.name);
    }
  }, [account]);
  
  return (
    <>
      <Button className={classN} onClick={connect}>
        <img
          src='assets/mask.svg'
          style={{ width: 24, display: "inline-block" }}
          alt=''
        />
        &nbsp; Connect wallet
      </Button>
    </>
  );
};

export default ConnectWallet;
