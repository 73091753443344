/** @format */

import React, { useState } from "react";
import Notiflix from "notiflix";
import ConnectWallet from "./tokenData/components/wallet/ConnectWallet";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [err, setErr] = useState("");

  Notiflix.Block.init({
    backgroundColor: 'rgba(0,0,0,0.5)',
    svgColor: '#f8f8f8',
    messageMaxLength: 19,
    });


  return (
    <>
      <div className='  '>
        <div className='box m-auto center-box px-3 py-3 pb-5 mt-5 mb-5'>
          <div className='d-flex align-items-center pb-5 '>
            <img src='assets/logo.svg' alt='' className="m-auto" width={180} />
          </div>
          <h4 className='mb-3'>Connect Metamask to interact with AI Square</h4>
          {/* <input
            type='text'
            name='username'
            placeholder='username'
            value={userName}
            onChange={e => setUserName(e.target.value)}
          /> */}
          <p className='text-danger fw-bold mt-2'>{err}</p>

          <div className='text-center mt-4'>
         <ConnectWallet  />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
