/** @format */

import React from "react";
import SearchBar from "../components/elements/SearchBar";

const Home = () => {
  return (
    <React.Fragment>
      <SearchBar />
    </React.Fragment>
  );
};

export default Home;
