/** @format */

import React from "react";

const Cards = () => {
  const image = [
    "assets/1.png",
    "assets/2.png",
    "assets/3.png",
    "assets/4.png",
    "assets/5.png",
    "assets/6.png",
    "assets/7.png",
    "assets/8.png",
  ];
  return (
    <div className='container mt-5'>
      <div className='row'>
        {image.map((v, i) => {
          return (
            <div className='col-md-3 mt-3 col-6' key={i}>
              <img src={v} className='w-100' alt='images' />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Cards;
